import React from "react";
import Fade from "react-reveal/Fade";
const AboutSection = () => {
  return (
    <div className="about-section" id="ncoin">
      <Fade cascade bottom duration={1000}>
        <div className="about-wrapper">
          <div className="container">
            <header className="about-header">
              <h1 className="about__title">ABOUT VICTORY GEMS</h1>
              <p className="about__extract">
              Compete to earn Victory Gems (gVTG), the game’s main in-game currency. You can use gVTG to get Olympic NFT pins or convert them to Victory Gem tokens (VTG), a cryptocurrency on the Binance Smart Chain.
              </p>
            </header>
          </div>

          <div className="container">
            <div className="about-gem-wrapper">
              <div className="sources-col">
                <div className="col-header">
                  <h2 className="col-title">sources</h2>
                  <div className="doted-line"></div>
                </div>

                <div className="sources-col-body">
                  <div className="sources-col-wrapper">
                    <div className="into-col">
                      <div className="anounce-wrapper">
                        <div className="featured-image-container">
                          <img className="f-image" src="/assets/images/compete-to-earn.png" />
                        </div>
                        <div className="text-box-wrapper">
                          <h4 className="box-title">COMPETE TO EARN</h4>
                          <div className="item-container">
                            <div className="item-check">
                              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                              </svg>
                            </div>
                            <div className="item-text">
                              <p className="text">Rewards given to top finishers.</p>
                            </div>
                          </div>
                          <div className="item-container">
                            <div className="item-check">
                              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                              </svg>
                            </div>
                            <div className="item-text">
                            <p className="text">More competitive tiers of play grant greater rewards.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="into-col">
                      <div className="middle-circle">
                        <div className="featured-image-container">
                          <img className="f-image" src="/assets/images/gem-old.png" />
                        </div>
                        <h2 className="middle-circle-title">gVTG</h2>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="usage-col">
                <div className="col-header">
                  <h2 className="col-title">usage</h2>
                  <div className="doted-line"></div>
                </div>
                <div className="usage-col-body">
                  <div className="into-col">
                    <div className="anounce-wrapper">
                      <div className="featured-image-container">
                        <img className="f-image" src="/assets/images/featured-box.png" />
                      </div>
                      <div className="text-box-wrapper">
                        <div className="item-container">
                          <div className="item-check">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                            </svg>
                          </div>
                          <div className="item-text">
                            <p className="text">Open boxes to get NFT pins &amp; skins.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="anounce-wrapper">
                      <div className="featured-image-container">
                        <img className="f-image" src="/assets/images/gem.png" />
                      </div>
                      <div className="text-box-wrapper">
                        <div className="item-container">
                          <div className="item-check">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                            </svg>
                          </div>
                          <div className="item-text">
                            <p className="text">Claim for <span className="featured-text">VTG</span>, a cryptocurrency on Binance Smart Chain.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="into-col">
                    <div className="middle-circle">
                      <div className="featured-image-container">
                        <img className="f-image" src="/assets/images/featured-wallet.png" />
                      </div>
                      <h2 className="middle-circle-title">Player Wallet</h2>
                    </div>
                  </div>

                  <div className="into-col">
                    <div className="anounce-wrapper">
                      <div className="featured-image-container">
                        <img className="f-image" src="/assets/images/featured-money.png" />
                      </div>
                      <div className="text-box-wrapper">
                        <div className="item-container">
                          <div className="item-check">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                            </svg>
                          </div>
                          <div className="item-text">
                            <p className="text">Sell on cryptocurrency exchanges.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="anounce-wrapper">
                      <div className="featured-image-container">
                        <img className="f-image" src="/assets/images/featured-pins.png" />
                      </div>
                      <div className="text-box-wrapper">
                        <div className="item-container">
                          <div className="item-check">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 6.84615L5.78947 11L14 2" stroke="#FDC51F" stroke-width="4" stroke-linecap="round"/>
                            </svg>
                          </div>
                          <div className="item-text">
                            <p className="text">Spend on purchases on nWayPlay Marketplace.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            {/*
            <p className="about__disclamer">
              The token is not yet available in Uniswap, Pancake Swap or any other DEX.
              <br></br>
              We will post the token smart contract address once it is available.
            </p>
            */}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default AboutSection;
