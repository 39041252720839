import React from "react";
function FullVideo() {
  return (
    <section className="full-video-section" id="trailer">
      <video
        className="trailer trailer--desktop"
        playsInline="playsInline"
        autoPlay="none"
        muted="muted"
        loop="loop"
      >
        <source
          src="https://olympicpins-storage.s3.us-east-2.amazonaws.com/videos/Olympic+Games+Jam+-+Beijing+2022+-+Launch+Trailer-720.mp4"
          type="video/mp4"
        />
      </video>

      <video
        className="trailer trailer--mobile"
        playsInline="playsinline"
        autoPlay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source
          src="https://olympicpins-storage.s3.us-east-2.amazonaws.com/videos/Olympic+Games+Jam+-+Beijing+2022+-+Launch+Trailer-720.mp4"
          type="video/mp4"
        />
      </video>

      <div className="black-overlay"></div>
    </section>
  );
}

export default FullVideo;
