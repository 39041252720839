import axios from "axios";

const instance = axios.create({
  //baseURL: "http://localhost:4000", //localhost
  //baseURL: "http://18.117.132.143:4000", // dev
  baseURL: 'https://service.nwayplay.com:4443', // prod
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 400) || status === 422;
  },
});

export default instance;
