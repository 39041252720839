import { useState } from "react";
import { mailingSubscribe } from "../../services";
import Bounce from "react-reveal/Bounce";

function NewsletterForm({ onOpenModal, onCloseModal }) {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  function handleChange(e) {
    const { value } = e.target;
    setEmailSent(value);
  }

  async function handleEmail() {
    if (emailSent) {
      if (!validateEmail(emailSent)) {
        setError("Input a valid email address.");
        return;
      } else {
        const { data } = await mailingSubscribe(emailSent);
        if (data.success) {
          setEmailSent(null)
          onOpenModal(
            "Thank you!",
            true,
            "Your subcriptios has been confirmed.",
            "close"
          );

        } else {
          if (!data.body) {
            const { errors } = data;
            for (let i in errors) {
              let error = errors[i];
              let messageError = "";
              Object.entries(error).forEach(([key, value]) => {
                messageError = value.error;
              });
              onOpenModal("Oups!", false, messageError, "close");
            }
          } else onOpenModal("Oups!", false, data.body, "close");
        }
      }
    }
  }
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="newsletter-form-section">
      <div className="container">
        <Bounce left duration={1000}>
          <div className="newsletter-form-wrapper">
            <div className="middle-column">
              <h1 className="newsletter-form-title">STAY UP TO DATE</h1>
              <div className="option-wrapper">
                <input
                  onChange={handleChange}
                  className="newsletter-input-text"
                  type="text"
                  placeholder="email address"
                />
                <button className="newsletter-input-submit" onClick={handleEmail}>
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </div>
        </Bounce>
      </div>
    </div>
  );
}

export default NewsletterForm;
