import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import FirstSlide from "./Slides/FirstSlide";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function BannerSlider(props) {
  const properties = {
    infinite: true,
    autoplay: false,
    indicators: true,
    indicators: i => <div className="custom-indicator"></div>,
    prevArrow: (
      <div
        style={{
          position: "absolute",
          top: "90%",
          left: "0",
          transform: "translateY(40%)",
        }}
      >
        <img
          className="arrow prev-arrow"
          src="assets/images/banner-slider-back-arrow.png"
        />
      </div>
    ),
    nextArrow: (
      <div
        style={{
          position: "absolute",
          top: "90%",
          right: "0",
          transform: "translateY(40%)",
        }}
      >
        <img
          className="arrow right-arrow"
          src="assets/images/banner-slider-next-arrow.png"
        />
      </div>
    ),
  };

  return (
    <>
      {
        <section class="banner-slider-section" id="about">
          <Fade bottom cascade duration={1000}>
            <div className="mobile-video-trailer" id="mobile-video-trailer">
              <div className="container">
                <div className="trailer-container">
                  <video
                    playsInline="playsInline"
                    className="video-container fade-in-up"
                    autoPlay="none"
                    muted="muted"
                    loop="loop"
                  >
                    <source
                      src="https://olympicpins-storage.s3.us-east-2.amazonaws.com/videos/Olympic+Games+Jam+-+Beijing+2022+-+Official+Launch+Trailer.mp4"
                      type="video/mp4"
                    />
                </video>
                </div>
                <div className="btn-row">
                  {/*
                  <a className="main-btn" href="#">
                    WATCH TRAILER
                  </a>
                  */}
                </div>
              </div>
            </div>

            <div className="container">
              <div className="slider-wrapper">
                <Slide {...properties}>
                  <div key="3">
                    <FirstSlide
                      title="MAYHEM EN MASSE"
                      description="Weave your way to the head of the pack in snowboard cross, pull insane tricks in slopestyle, and hog up all the power-ups in skeleton as you earn your way to the top of the podium. It&apos;s Olympic sports like you&apos;ve never seen before!"
                      image="assets/images/1mayhem_en_mass_1920x1080.jpg"
                    />
                  </div>
                  <div key="1">
                    <FirstSlide
                      title="YOU DO YOU"
                      description="While the competition may be ruthless, at least look your best when you flop.
                Customize your avatar from top hats to Yeti suits to express your
                individuality and stand out from the pack. "
                      image="assets/images/2youdoyou.png"
                    />
                  </div>
                  <div key="2">
                    <FirstSlide
                      title="PLAY TO EARN"
                      description="Compete to earn gVTG and VTG and get valuable NFTs that can be traded on the nWayPlay Marketplace for real money."
                      image="assets/images/3play-to-earn_w_characters.png"
                    />
                  </div>
                </Slide>
              </div>
            </div>
            <div className="btn-row">
              <Link className="main-btn" to="/media">
                media
              </Link>
            </div>
          </Fade>
        </section>
      }
    </>
  );
}

export default BannerSlider;
