import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ onClickVideoSetOpen }) => {
  const [showNav, setShowNav] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const controlNavbar = () => {
    if (window.scrollY > 75) {
      // console.log("whatt")
      setShowLogo(true);
    } else {
      setShowLogo(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  function handleClick() {
    setShowNav(prevState => !prevState);
    if (!showNav){
      document.body.style.overflowY = 'hidden';
    } else{
      document.body.style.overflowY = 'auto';
    }
  }

  const handleLinkTrailer = () => {
    setShowNav(prevState => !prevState);
    navigate("/#trailer")
    if (!window.location.href.includes('/media')) {
      onClickVideoSetOpen()
    }
  }
  return (
    <>
      <nav className="navbar-container">
        <div className="container">
          <div className="nav-wrapper">
            <div className="brand-logo-wrapper">
              <img
                className={`brand-logo ${showLogo ? "show" : ""}`}
                src="/assets/images/olympic-jam-game-logo.png"
              />
            </div>
            <div className={`right-container ${showNav ? "show" : ""}`}>
              <button
                className="close-nav"
                aria-label="Close navigation"
                onClick={handleClick}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.03649 16.1255L16.2506 2.91144C16.5047 2.65732 16.5364 2.18085 16.2506 1.89497L14.98 0.624389C14.7259 0.370272 14.2176 0.370272 13.9635 0.624389L0.749444 13.8384C0.463563 14.1243 0.495327 14.6008 0.749444 14.8549L2.02003 16.1255C2.30591 16.4114 2.75061 16.4114 3.03649 16.1255Z"
                    fill="black"
                  />
                  <path
                    d="M16.2505 13.8385L3.03644 0.624451C2.78232 0.370334 2.30585 0.33857 2.01997 0.624451L0.749388 1.89503C0.495271 2.14915 0.495271 2.65738 0.749388 2.9115L13.9634 16.1256C14.2493 16.4114 14.7258 16.3797 14.9799 16.1256L16.2505 14.855C16.5364 14.5691 16.5364 14.1244 16.2505 13.8385Z"
                    fill="black"
                  />
                </svg>
              </button>
              {useLocation.pathname === "/" ? (
                <>

                  <a className="nav-link" href="#main" onClick={handleClick}>
                    Home
                  </a>
                  
                  <a className="nav-link desktop-trailer-link" onClick={handleLinkTrailer}>
                    Trailer
                  </a>

                  <a className="nav-link midle-trailer-link" href="#trailer" onClick={handleClick}>
                    Trailer
                  </a>

                  <a className="nav-link mobile-trailer-link" href="#mobile-video-trailer" onClick={handleClick}>
                    Trailer
                  </a>
                  

                  <a className="nav-link" href="#about" onClick={handleClick}>
                    About
                  </a>

                  <a className="nav-link" href="/media" onClick={handleClick}>
                    Media
                  </a>
                  {/*
                  <Link to="/media" className="nav-link">
                    Media
                  </Link>
                  */}
                  <a className="nav-link" href="#nft" onClick={handleClick}>
                    NFTs
                  </a>
                  <a className="nav-link" href="#ncoin" onClick={handleClick}>
                    $VTG
                  </a>
                  
                  <a className="nav-link" href="#roadmap" onClick={handleClick}>
                    Roadmap
                  </a>
                  
                  <a className="nav-link" href="#developer" onClick={handleClick}>
                    Developer
                  </a>
                  <a
                    className="nav-link"
                    href="https://blog.nwayplay.com"
                    target="_blank"
                  >
                    News
                  </a>
                  <a
                    className="nav-link"
                    href="https://nwayplay.com/marketplace"
                    target="_blank"
                  >
                    Marketplace
                  </a>
                </>
              ) : (
                <>

                  <a className="nav-link" href="/#main" onClick={handleClick}>
                    Home
                  </a>
                  
                  <a className="nav-link desktop-trailer-link" onClick={handleLinkTrailer}>
                    Trailer
                  </a>

                  <a className="nav-link midle-trailer-link" href="#trailer" onClick={handleClick}>
                    Trailer
                  </a>

                  <a className="nav-link mobile-trailer-link" href="#mobile-video-trailer" onClick={handleClick}>
                    Trailer
                  </a>
                  

                  <a className="nav-link" href="/#about" onClick={handleClick}>
                    About
                  </a>
                  
                  <a className="nav-link" href="/media" onClick={handleClick}>
                    Media 
                  </a>
                  

                  <a className="nav-link" href="/#nft" onClick={handleClick}>
                    NFTs
                  </a>
                  <a className="nav-link" href="/#ncoin" onClick={handleClick}> 
                    $VTG
                  </a>
                  
                  <a className="nav-link" href="/#roadmap" onClick={handleClick}>
                    Roadmap
                  </a>
                  
                  <a className="nav-link" href="/#developer" onClick={handleClick}>
                    Developer
                  </a>
                  <a
                    className="nav-link"
                    href="https://blog.nwayplay.com"
                    target="_blank"
                  >
                    News
                  </a>
                  <a
                    className="nav-link not-at-mobile"
                    href="https://nwayplay.com/marketplace"
                    target="_blank"
                  >
                    Marketplace
                  </a>
                </>
              )}
            </div>
            <div className="only-mobile-btn">
              <a
                className="main-btn"
                href="https://nwayplay.com/marketplace"
                target="_blank"
              >
                Marketplace
              </a>
            </div>
            <div className="nav-mobile-container">
              <div className="trigger-wrapper" onClick={handleClick}>
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.15625 3.80469H19.8438C20.2031 3.80469 20.5625 3.49023 20.5625 3.08594V1.28906C20.5625 0.929688 20.2031 0.570312 19.8438 0.570312H1.15625C0.751953 0.570312 0.4375 0.929688 0.4375 1.28906V3.08594C0.4375 3.49023 0.751953 3.80469 1.15625 3.80469ZM1.15625 10.9922H19.8438C20.2031 10.9922 20.5625 10.6777 20.5625 10.2734V8.47656C20.5625 8.11719 20.2031 7.75781 19.8438 7.75781H1.15625C0.751953 7.75781 0.4375 8.11719 0.4375 8.47656V10.2734C0.4375 10.6777 0.751953 10.9922 1.15625 10.9922ZM1.15625 18.1797H19.8438C20.2031 18.1797 20.5625 17.8652 20.5625 17.4609V15.6641C20.5625 15.3047 20.2031 14.9453 19.8438 14.9453H1.15625C0.751953 14.9453 0.4375 15.3047 0.4375 15.6641V17.4609C0.4375 17.8652 0.751953 18.1797 1.15625 18.1797Z"
                    fill="#37B2E3"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
