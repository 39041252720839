function FirstSlide(props) {
  return (
    <>
      <section className="single-slide-section">
        <div className="left-col">
          <h1 className="title">{props.title}</h1>
          <p className="description">
            {props.description}
          </p>
        </div>
        <div className="right-col">
          <div className="featured-wrapper">
            <img className="featured-slide-image" src={props.image} />
          </div>
        </div>
      </section>
    </>
  );
}
export default FirstSlide;
