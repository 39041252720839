import React, { useState } from "react";

const Header = ({ onClickVideoSetOpen }) => {

  return (
    <div className="hero-section" id="main">
      {/* 
      <img className="animated-background upscale" src="/assets/images/Olympic_Games_Jam_backgound-main.jpg" />
      */}
      <div className="container ">
        <div className="hero-wrapper">
          <div className="left-col">
            <h3 className="hero__subtitle fade-in-left" >WELCOME TO THE WORLD’S BIGGEST</h3>
            <h1 className="hero__title-big fade-in-left slow">WINTER</h1>
            <h1 className="hero__title-small fade-in-left slow">CELEBRATION!</h1>
            <p className="hero__extract">
              Olympic Games Jam: Beijing 2022 is a wild party game on Android and iOS
              where up to 20 players compete in real-time through a
              series of chaotic mini-games until one champion emerges. Let the Olympic Games begin!
            </p>
            <div
              className="buton_trailer_container"
              onClick={onClickVideoSetOpen}
            >
              <a className="main-btn">view trailer</a>
            </div>
            <p className="download__subtitle">Download now:</p>
            <div className="links-wrapper">
              <a className="store-link fade-in-up" href="https://play.google.com/store/apps/details?id=com.nway.olyw" target="_blank">
                <img
                  className="single-store-link"
                  src="/assets/images/store-google.svg"
                />
              </a>
              <a className="store-link fade-in-up slow" href="https://apps.apple.com/us/app/olympic-games-jam-beijing-2022/id1600205176" target="_blank">
              {/* <a className="store-link aple-link fade-in-up slow"> */}
                <img
                  className="single-store-link"
                  src="/assets/images/store-apple.svg"
                />
              </a>
              {/*
              <a className="main-btn-with-icon" href="#">
                <img className="icon-btn" src="/assets/images/icon-btn-android.svg"/>
                <span className="text-btn">ANDROID</span>
              </a>
              <a className="main-btn-with-icon" href="#">
                <img className="icon-btn" src="/assets/images/icon-btn-apple.svg"/>
                <span className="text-btn">IOS</span>
              </a>
              */}
              <div className="beijin-ovg-container">
                <img className="ovg-logo" src="/assets/images/ovg-logo-beijing2022-full-colour1.png" />
              </div>
            </div>
          </div>
          <div className="right-col">
            {/*
            <div
              className="buton_trailer_container"
              onClick={() => videoSetOpen(true)}
            >
              <a className="main-btn">trailer</a>
            </div>
            */}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Header;
