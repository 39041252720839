import React, { useEffect, useState } from "react";

const Header = () => {
    return (
        <>
        <div className="banner-header-alt-pages">
          <h1 className="banner-title">TERMS OF SERVICE</h1>
        </div>
        </>
    );
}

export default Header;