import { Link } from "react-router-dom";
import "./style.scss";
import NwayImage from "../../nway-image.svg";
function MainBar() {
  return (
    <>
      <div className="navbar-container-all">
        <div className="navbar-fixed" style={{ height: "65px" }}>
          <nav className="mainbar">
            <div className="container">
              <div
                className="nav-wrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0 20px",
                }}
              >
                <div className="mainbar__logo">
                  <a
                    href="https://nwayplay.com/"
                    target="_blank"
                    className="brand-logo logo-link"
                  >
                    <>
                      <img className="logo-img" src={NwayImage} />
                    </>
                  </a>
                </div>
                <ul
                  className="right mainbar__mainmenu"
                  style={{ display: "flex" }}
                >
                  <li className="mainmenu__item">
                    <div className="nav-link">
                      <a href="https://nwayplay.com/boxes" target="_blank">
                        BOXES
                      </a>
                    </div>
                  </li>

                  <li className="mainmenu__item">
                    <div className="nav-link" style={{ cursor: "pointer" }}>
                      <a
                        href="https://nwayplay.com/marketplace"
                        target="_blank"
                      >
                        MARKETPLACE
                      </a>
                    </div>
                  </li>

                  <li className="mainmenu__item">
                    <div className="nav-link">
                      <a href="https://blog.nwayplay.com/" target="_blank">
                        news
                      </a>
                    </div>
                  </li>
                  <li className="mainmenu__item">
                    <div className="nav-link">
                      <a href="https://nwayplay.com/about" target="_blank">
                        about
                      </a>
                    </div>
                  </li>
                  <li className="mainmenu__item">
                    <div
                      href="https://support.nwayplay.com/hc/en-us"
                      target="_blank"
                      className="nav-link"
                    >
                      <a
                        href="https://support.nwayplay.com/hc/en-us"
                        target="_blank"
                      >
                        FAQ
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default MainBar;
