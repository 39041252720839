import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import MainBar from "../../components/MainBar";
import Footer from "../../components/Footer";
import Header from "./header";
import Content from "./content";
import ModalVideo from "react-modal-video";

const PrivacyPolicy = () => {

    const [videoModal, setVideoModal] = useState(false);

    const handleClickVideoIsOpen = () => {
        handleOpenVideoModal(true)
    };

    const handleOpenVideoModal = () => {
        setVideoModal(true)
    };

    const handleCloseVideoModal = () => {
        setVideoModal(false)
    };

    return (
        <>
            <MainBar />
            <Navbar onClickVideoSetOpen={handleClickVideoIsOpen} />
            <Header />
            <Content />
            <Footer />
            <ModalVideo
                channel="youtube"
                autoPlay
                playsInline
                isOpen={videoModal}
                videoId="yOfRLiU099c"
                onClose={handleCloseVideoModal}
            />
        </>
    );
}

export default PrivacyPolicy;
