import Carousel, { consts } from "react-elastic-carousel";
import PinSkeleton from "../PinSkeleton/pinSkeleton";
import Piece from "../Piece";
import Fade from "react-reveal/Fade";

function HomeSlider(params) {
  const breakPoints = [
    //{ width: 1, itemsToShow: 1, itemsToScroll: 2, pagination: true },
    { width: 600, itemsToShow: 2, itemsToScroll: 1, pagination: true },
    { width: 850, itemsToShow: 3, itemsToScroll: 2, pagination: true },
    { width: 1150, itemsToShow: 4, itemsToScroll: 3, pagination: true },
  ];

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <div className="arrow-wrapper">
          <img
            className="arrow back-arrow"
            src="assets/images/banner-slider-back-arrow.png"
          />
        </div>
      ) : (
        <div className="arrow-wrapper">
          <img
            className="arrow next-arrow"
            src="assets/images/banner-slider-next-arrow.png"
          />
        </div>
      );

    return (
      <div onClick={onClick} disabled={isEdge}>
        {pointer}
      </div>
    );
  }

  return (
    <>
      {
        <section className="earn-nft-section" id="nft">
          <Fade bottom cascade duration={1000}>
            <div className="container">
              <div className="earn-header">
                <h1 className="earn-header__title">EARN OLYMPIC NFT DIGITAL PINS</h1>
                <p className="earn-header__subtitle">
                  Play Olympic Games Jam: Beijing 2022 and earn rare and valuable NFTs that
                  can be traded on the nWayPlay Marketplace for real money.
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom cascade duration={1000}>
            <div className="container">
              <div className="earn-slider" style={{ maxWidth: "100%" }}>
                {pieces.length > 0 ? (
                  <Carousel
                    breakPoints={breakPoints}
                    renderArrow={myArrow}
                    renderPagination={({ pages, activePage, onClick }) => {
                      return (
                        <div className="indicators">
                          {pages.map(page => {
                            const isActivePage = activePage === page;
                            return (
                              <div
                                className={
                                  isActivePage
                                    ? "custom-indicator active"
                                    : "custom-indicator"
                                }
                                key={page}
                                onClick={() => onClick(page)}
                                active={isActivePage}
                              ></div>
                            );
                          })}
                        </div>
                      );
                    }}
                    easing="cubic-bezier(0.61, 1, 0.88, 1)"
                    tiltEasing="cubic-bezier(0.61, 1, 0.88, 1)"
                    transitionMs={600}
                  >
                    {pieces &&
                      pieces.map((item, key) => {
                        return <Piece key={key} item={item} />;
                      })}
                  </Carousel>
                ) : (
                  <div className="row no-margin">
                    <PinSkeleton
                      type="feed"
                      lengthDesktop={4}
                      lengthMobile={2}
                      lengthTablet={3}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*
            <div className="container">
              <div className="row-btn-container">
                <a href="https://nwayplay.com/marketplace" target="_blank">
                  <button className="main-btn">MARKETPLACE</button>
                </a>
              </div>
            </div>
            */}
          </Fade>
        </section>
      }
    </>
  );
}

export default HomeSlider;

const pieces = [
  {
    setPinId: "e462e747-bdfd-4fa7-b87a-15360b5fa7fe",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/7bb97edf-b53b-4cf1-a176-8ad1dfd4baa8_mascot_tokyo_2020_skateboarding_legendary.mp4",
    average: "499.00",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/7bb97edf-b53b-4cf1-a176-8ad1dfd4baa8_tokyo_2020_pin_collection_legendary_skateboarding.png",
    listingCount: 9,
    listingDate: 1639184455235,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 1499,
    pinName: "Skateboarding Tokyo 2020 Mascot",
    rarity: "Legendary",
    season: "Summer",
    setName: "Tokyo 2020 Legendary",
    topSale: 499,
    type: "Mascot Pin",
    year: "2020",
    country: "Japan",
    description:
      "The sport of skateboarding will make its Olympic debut at the Tokyo 2020 Olympic Games. The Tokyo 2020 Olympic Games skateboarding courses are a culmination of experiences derived from a number of world championship and qualifying events. This event will be hosted at the Aomi Urban Sports Venue, home to the Games' newest events, increasing the opportunity for engagement with fans and delivering a unique Tokyo 2020 Olympic Games experience.",
    serial_max: 55,
  },
  {
    setPinId: "24257717-203a-47f9-9222-3c9b8369dd99",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/aafd1f47-1c8a-4677-9d2b-73e9ed63840f_poster_lakePlacid_1932_epic.mp4",
    average: "69.25",
    city: "Lake Placid",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/aafd1f47-1c8a-4677-9d2b-73e9ed63840f_epic_poster_1932_lakeplacid.png",
    listingCount: 31,
    listingDate: 1641990468780,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 90,
    pinName: "Lake Placid 1932 Poster",
    rarity: "Epic",
    season: "Winter",
    setName: "Premiere Edition Epic",
    topSale: 99,
    type: "Poster Pin",
    year: "1932",
    country: "USA",
    description:
      "The first Olympic Winter Games to be held in the United States were hosted by Lake Placid, New York.  Athletes from 17 countries competed in the competitions in bobsleigh, ice hockey, skating and skiing.",
    serial_max: 1200,
  },
  {
    setPinId: "0f0e7af3-2e79-4663-8e1a-0473d2b71218",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/6fe48810-49f3-49e9-a249-98a72bc51795_emblem_innsbruck_1976_rare.mp4",
    average: "17.86",
    city: "Innsbruck",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/6fe48810-49f3-49e9-a249-98a72bc51795_rare_mascot_1976_innsbruck.png",
    listingCount: 46,
    listingDate: 1641973757406,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 29,
    pinName: "Schneemandl, Innsbruck 1976 Mascot - Variant 2",
    rarity: "Rare",
    season: "Winter",
    setName: "Premiere Edition Rare",
    topSale: 39,
    type: "Mascot Pin",
    year: "1976",
    country: "Austria",
    description:
      "The XII Olympic Winter Games, which were held in Innsbruck, Austria, in 1976, featured the first official Olympic Winter Games mascot, Schneemandl. Schneemandl was also considered a lucky charm, as it brought plenty of snow to Innsbruck in 1976.",
    serial_max: 2700,
  },
  {
    setPinId: "e2ffbc85-a98e-44f9-a702-2858edee2ac6",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/aeaf7756-e884-4168-a992-10015be1c9f1_pictogram_tokyo_1964_boxing_common.mp4",
    average: "1.88",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/aeaf7756-e884-4168-a992-10015be1c9f1_common_pictogram_1964_tokyo_boxing.png",
    listingCount: 91,
    listingDate: 1641919494757,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 3,
    pinName: "Boxing Tokyo 1964 Pictogram",
    rarity: "Common",
    season: "Summer",
    setName: "Premiere Edition Common",
    topSale: 10,
    type: "Pictogram Pin",
    year: "1964",
    country: "Japan",
    description:
      "The Olympic Games Tokyo 1964 were the first to be held in Asia, and the first in history to feature pictograms. There were 10 boxing events for men only.",
    serial_max: 7000,
  },
  {
    setPinId: "17324d6e-65fa-413b-913a-7705b2500169",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/95190fdc-0121-4931-ba19-4f48323b0f5d_poster_athens_1896_legendary.mp4",
    average: "800.00",
    city: "Athens",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/95190fdc-0121-4931-ba19-4f48323b0f5d_legendary_poster_1896_athens.png",
    listingCount: 12,
    listingDate: 1641722474523,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 1999,
    pinName: "Athens 1896 Poster",
    rarity: "Legendary",
    season: "Summer",
    setName: "Premiere Edition Legendary",
    topSale: 999,
    type: "Poster Pin",
    year: "1896",
    country: "Greece",
    description:
      "The first Olympic Games in modern history were held in Athens in 1896. Athens was chosen to stage the inaugural modern Games by the IOC because Greece was the birthplace of the ancient Olympic Games. Officially known as the Games of the I Olympiad, the event featured 14 delegations.",
    serial_max: 250,
  },
  {
    setPinId: "7c4e3460-e5fa-4628-bc18-4d4a73539989",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/b143acb5-c2a2-45b3-a154-ab72286514f9_mascot_tokyo_2020_rare_beachvolleyball.mp4",
    average: "10.00",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/b143acb5-c2a2-45b3-a154-ab72286514f9_tokyo_2020_pin_collection_rare_beach_volleyball.png",
    listingCount: 28,
    listingDate: 1641691828352,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 45,
    pinName: "Beach Volleyball Tokyo 2020 Mascot",
    rarity: "Rare",
    season: "Summer",
    setName: "Tokyo 2020 Rare",
    topSale: 120,
    type: "Mascot Pin",
    year: "2020",
    country: "Japan",
    description:
      "Beach volleyball made its Olympic debut at the Barcelona 1992 Olympic Games as a demonstration sport, and became part of the sports programme at the Atlanta 1996 Olympic Games. At the Tokyo 2020 Olympic Games there will be 24 men's teams and 24 women's teams competing. Each competition will begin with six pools of four teams. The first two ranked teams from each pool, and the best two teams that rank third in their respective pools, will advance automatically to the round of 16 of the final phase.",
    serial_max: 253,
  },
  {
    setPinId: "15513f55-999d-474a-a4e3-432c74eee44a",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/8294c824-fefd-469f-ba41-d14ea193fcc2_mascot_seoul_1988_epic_cycling.mp4",
    average: "1.00",
    city: "Seoul",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/8294c824-fefd-469f-ba41-d14ea193fcc2_mascot_seoul_1988_epic_cycling.png",
    listingCount: 2,
    listingDate: 1639847055120,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 999,
    pinName: "Hodori Cycling Seoul 1988 Mascot",
    rarity: "Epic",
    season: "Summer",
    setName: "Iconic Edition Epic",
    topSale: 1,
    type: "Mascot Pin",
    year: "1988",
    country: "Korea",
    description:
      "As the mascot of the Olympic Games Seoul 1988, Hodori, the Korean tiger, long familiar to Koreans through myths and legend, was designed in the simple figurative form of a little tiger cub. The variation of the mascot's form was done in four fields including sports, Games support, folk themes, and pictographs. The sports variations of the mascot involved 28 types including a Cycling variation. The road cycling program in 1988 was the same as in 1984, with individual road races for men and women, and a men's team trial. The women's road race was a mass start race of 82.0 km. on the Tongillo Road Course. Men raced a mass start individual road race of 196.8 km. and a 100 km. four-man team time trial, both on the Tongillo Road Course.",
    serial_max: 151,
  },

  {
    setPinId: "9423c443-9abb-4fd3-8ab0-6b93849a6055",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/e336cf9d-8b99-4807-9a11-f83ab59ee2d4_mascot_tokyo_2020_rare_surfing.mp4",
    average: "34.56",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/e336cf9d-8b99-4807-9a11-f83ab59ee2d4_tokyo_2020_pin_collection_rare_surfing.png",
    listingCount: 28,
    listingDate: 1641915083178,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 70,
    pinName: "Surfing Tokyo 2020 Mascot",
    rarity: "Rare",
    season: "Summer",
    setName: "Tokyo 2020 Rare",
    topSale: 99,
    type: "Mascot Pin",
    year: "2020",
    country: "Japan",
    description:
      "Surfing is one of five additional sports proposed by the Tokyo 2020 Organising Committee to bring more youthful and vibrant events and culture into the Olympic programme. In 2016, its inclusion was approved by the International Olympic Committee. Being the inaugural Olympic surfing event, all the countries who have qualified will be eager to make their mark.",
    serial_max: 253,
  },
  {
    setPinId: "df9edba6-d27d-40d7-bb69-30c189b28be0",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/43a561af-3785-446f-9925-3e90bda2c81d_barcelona_1992_common_football_08.mp4",
    average: "15.00",
    city: "Barcelona",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/43a561af-3785-446f-9925-3e90bda2c81d_pic_Barcelona_1992_08_common.png",
    listingCount: 8,
    listingDate: 1641329233196,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
    lowestAsk: 20,
    pinName: "Football Barcelona 1992 Pictogram",
    rarity: "Common",
    season: "Summer",
    setName: "Iconic Edition Common",
    topSale: 19,
    type: "Pictogram Pin",
    year: "1992",
    country: "Spain",
    description:
      "This was the last time that only men would compete in Olympic football as women would be on the pitch for the Olympic Games Atlanta 1996. But at Barcelona, the same format since 1976 was used – 16 teams separated into four four-team groups that played a round-robin to determine the top two teams in each group, who advanced to the quarter-finals and the knock-out phase. The venues were spread around Spain, as was now de rigeur, with the following stadia used – Estadio del FC Barcelona, Barcelona; Estadio de Sarriá, Barcelona; Estadio Luis Casanova, Valencia; Estadio de La Romareda, Zaragoza; and Estadio de la Nova Creu Alta, Sabadell.",
    serial_max: 677,
  },
  {
    setPinId: "c1dfa53d-5ec6-4f22-92bb-300d448238c0",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/2024da99-5eb3-49c8-95cf-2056bb9479b4_mascot_tokyo_2020_common_karatekumite.mp4",
    average: "7.63",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/2024da99-5eb3-49c8-95cf-2056bb9479b4_tokyo_2020_pin_collection_common_karate_kumite.png",
    listingCount: 23,
    listingDate: 1641973129262,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 9,
    pinName: "Karate Kumite Tokyo 2020 Mascot",
    rarity: "Common",
    season: "Summer",
    setName: "Tokyo 2020 Common",
    topSale: 20,
    type: "Mascot Pin",
    year: "2020",
    country: "Japan",
    description:
      "Karate is a martial art that originated in Okinawa during the Ryukyu Dynasty period. It spread throughout Japan during the 1920s and then worldwide. The Tokyo 2020 Olympic Games will feature 80 athletes. Eligibility will be determined by international rankings in the years leading up to karate's Olympic debut, with each country or region fielding only one competitor.",
    serial_max: 1631,
  },

  {
    setPinId: "7f7ac0ee-51b9-4207-88eb-5084d61e3d5e",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/42be8c1c-80e6-491e-ac19-ae44df30b49a_mascot_tokyo_2020_studying_epic.mp4",
    average: "50.00",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/42be8c1c-80e6-491e-ac19-ae44df30b49a_tokyo_2020_pin_collection_epic_studying.png",
    listingCount: 6,
    listingDate: 1641852648405,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 190,
    pinName: "Studying Tokyo 2020 Mascot",
    rarity: "Epic",
    season: "Summer",
    setName: "Tokyo 2020 Epic",
    topSale: 50,
    type: "Mascot Pin",
    year: "2020",
    country: "Japan",
    description:
      "The Tokyo 2020 Olympic Games Mascot, Miraitowa, studying. In Japanese, 'Mirai' means future, and 'Towa' means eternity, conveying both a sense of anticipation for the upcoming Tokyo 2020 Olympic Games as well as the lasting legacy the Games will have for generations.",
    serial_max: 91,
  },
  {
    setPinId: "f29ffa78-0f09-4580-8d69-b6c190731f23",
    animationURL:
      "https://assets.nwayplay.com/olympics/nft/9f1293d1-f870-4278-a12b-1ecb74a83f62_mascot_tokyo_2020_rare_emblem.mp4",
    average: "60.00",
    city: "Tokyo",
    imageURL:
      "https://assets.nwayplay.com/olympics/nft/9f1293d1-f870-4278-a12b-1ecb74a83f62_tokyo_2020_pin_collection_rare_emblem.png",
    listingCount: 22,
    listingDate: 1639803793449,
    logoURL:
      "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
    lowestAsk: 79,
    pinName: "Tokyo 2020 Emblem - Variant 2",
    rarity: "Rare",
    season: "Summer",
    setName: "Tokyo 2020 Rare",
    topSale: 60,
    type: "Emblem Pin",
    year: "2020",
    country: "Japan",
    description:
      'Composed of three varieties of rectangular shapes, the design represents different countries, cultures and ways of thinking. It incorporates the message of "Unity in Diversity." It also expresses the fact that the Olympic and Paralympic Games seek to promote diversity as a platform to connect the world.',
    serial_max: 253,
  },
  // {
  //   setPinId: "70d02f96-0023-4df3-a8a7-a8094fb1ad7b",
  //   animationURL:
  //     "https://assets.nwayplay.com/olympics/nft/1baaf5bb-d2f7-47c4-b002-10532437ee65_mascot_tokyo_2020_common_baseball.mp4",
  //   average: "89.00",
  //   city: "Tokyo",
  //   imageURL:
  //     "https://assets.nwayplay.com/olympics/nft/1baaf5bb-d2f7-47c4-b002-10532437ee65_tokyo_2020_pin_collection_common_baseball.png",
  //   listingCount: 33,
  //   listingDate: 1641970624597,
  //   logoURL:
  //     "https://assets.nwayplay.com/olympics/logos/Tokyo 2020 OLP Horizontal Logo.png",
  //   lowestAsk: 5,
  //   pinName: "Baseball Tokyo 2020 Mascot",
  //   rarity: "Common",
  //   season: "Summer",
  //   setName: "Tokyo 2020 Common",
  //   topSale: 255,
  //   type: "Mascot Pin",
  //   year: "2020",
  //   country: "Japan",
  //   description:
  //     "Last contested at the Beijing 2008 Olympic Games, baseball and its sister sport softball have been reinstated to the Olympic programme and will form part of the Tokyo 2020 Olympic Games. Baseball is extremely popular in the Americas and Asia. As host nation, the Japanese team's place in the forthcoming Games is assured and fans will be hoping for a strong performance against the sport's global powerhouses.",
  //   serial_max: 1631,
  // },
  // {
  //   setPinId: "601b6a64-96d0-44b4-8234-218114e15101",
  //   animationURL:
  //     "https://assets.nwayplay.com/olympics/nft/72f97b26-6990-4812-a088-ac3265296518_emblem_paris_1924_epic.mp4",
  //   average: "98.00",
  //   city: "Paris",
  //   imageURL:
  //     "https://assets.nwayplay.com/olympics/nft/72f97b26-6990-4812-a088-ac3265296518_epic_emblem_1924_paris.png",
  //   listingCount: 29,
  //   listingDate: 1640187980234,
  //   logoURL:
  //     "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
  //   lowestAsk: 128,
  //   pinName: "C.O.F. Officiel Paris 1924 Badge",
  //   rarity: "Epic",
  //   season: "Summer",
  //   setName: "Premiere Edition Epic",
  //   topSale: 125,
  //   type: "Badge Pin",
  //   year: "1924",
  //   country: "France",
  //   description:
  //     "The Games of the VIII Olympiad were held in Paris, France, in 1924, making Paris the first city to host the Olympic Games twice. This pin design is based on the designation badge worn by the French Olympic Committee (C.O.F.) officials.",
  //   serial_max: 1200,
  // },
  // {
  //   id: "7d474932-9730-40a6-9a9d-f3a95219517d",
  //   pinName: "Los Angeles 1932 Poster",
  //   description:
  //     "The Olympic Games came to America in 1932, but the depression and the travel distance from Europe kept the international turnout low. Less than half as many athletes competed as in 1928 as many nations sent only small squads. The Games of the X Olympiad saw the introduction of automatic timing in track & field, and an Olympic Village (although there had been a small one in 1924). The political situation was becoming a bit more tenuous in 1932, although that would only worsen in the ensuing decade.",
  //   story: "",
  //   year: "1932",
  //   season: "Summer",
  //   city: "Los Angeles",
  //   country: "United States of America",
  //   type: "Poster Pin",
  //   subname: "",
  //   rarity: "Legendary",
  //   imageURL:
  //     "https://assets.nwayplay.com/olympics/nft/7d474932-9730-40a6-9a9d-f3a95219517d_poster_LosAngeles_1932L.png",
  //   externalURL: "",
  //   animationURL:
  //     "https://assets.nwayplay.com/olympics/nft/7d474932-9730-40a6-9a9d-f3a95219517d_poster_LosAngeles_1932_legendary.mp4",
  //   transactionStatus: 4,
  //   pinId: 312,
  //   transactionId:
  //     "97a870285c3f04edaea12ed63dd1f8c2547bcd5071585c433a253486aff37c70",
  //   logoURL:
  //     "https://assets.nwayplay.com/olympics/logos/OOLP-Olympic-Heritage-logo-RGB_olympic-heritage-horizontal.jpg",
  //   setName: "Iconic Edition Legendary",
  //   serial_max: 6,
  // },
];
