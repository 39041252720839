import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import MainBar from "../../components/MainBar";
import Navbar from "../../components/Navbar";
import MediaPage from "../../components/PagesContainers/MediaPage";
import SocialMedia from "../../components/SocialMedia";
import ModalMessage from "../../components/modalMessage";
import NewsletterForm from "../../components/NewsletterForm";
import ModalVideo from "react-modal-video";






function Media() {
  const [messageIsOpenModal, setMessageIsOpenModal] = useState(false);
const [titleModal, setTitleModal] = useState(null);
const [isCheckOkModal, setIsCheckOkModal] = useState(true);
const [messageModal, setMessageModal] = useState(null);
const [messageButtonModal, setMessageButtonModal] = useState(null);
const [videoModal, setVideoModal] = useState(false);

useEffect(() => {
  if (window.location.href.includes('/#trailer')) {
    handleOpenVideoModal()
  }
}, []);

const handleCloseModalMessage = () => {
  setMessageIsOpenModal(false);
  setTitleModal(null);
  setIsCheckOkModal(true);
  setMessageModal(null);
  setMessageButtonModal(null);
};

const handleOpenModalMessage = (title, isCheckOk, message, messageButton) => {
  // debugger;
  setTitleModal(title);
  setIsCheckOkModal(isCheckOk);
  setMessageModal(message);
  setMessageButtonModal(messageButton);
  setMessageIsOpenModal(true);
};

const handleClickVideoIsOpen = () => {
  handleOpenVideoModal(true)
};

const handleOpenVideoModal = () => {
  setVideoModal(true)
};

const handleCloseVideoModal = () => {
  setVideoModal(false)
};
  return (
    <>
      <MainBar />
      <Navbar />
      <MediaPage />
      <SocialMedia />
      <NewsletterForm onOpenModal={handleOpenModalMessage}
        onCloseModal={handleCloseModalMessage} />
      <Footer />

      <ModalVideo
        channel="youtube"
        autoPlay
        playsInline
        isOpen={videoModal}
        videoId="onClickVideoSetOpen"
        onClose={handleCloseVideoModal}
      />

      <ModalMessage
        isOpen={messageIsOpenModal}
        onRequestClose={handleCloseModalMessage}
        isCheckOk={isCheckOkModal}
        title={titleModal}
        message={messageModal}
        messageButton={messageButtonModal}
      />
    </>
  );
}

export default Media;
