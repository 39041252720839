import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
//import "./style.scss";

function PinSkeleton({ type, lengthDesktop, lengthTablet, lengthMobile }) {
  const [size, setSize] = useState(4);
  const { innerWidth } = window;

  useEffect(() => {
    if (innerWidth >= 993) {
      setSize(lengthDesktop);
    } else if (innerWidth < 992 && innerWidth > 600) {
      setSize(lengthTablet);
    } else if (innerWidth < 600) {
      setSize(lengthMobile);
    }
  });

  const FeedSkeleton = () => (
    <div className="col s6 m4 l3" style={{ paddingBottom: "10px" }}>
      <article className="single-pack pin-sk1">
        <div className="single-frame-pin-container-skelton">
          <div className="skelton-frame">
            <Skeleton height={"100%"} />
          </div>
        </div>
        <div
          className="single-pin-info-container"
          style={{ padding: "1rem 0" }}
        >
          <h2 className="single-pack__title--left-aligned no-margin">
            <Skeleton height={"100%"} />
          </h2>
          {/*<p className="single-pack__description">{item?.description}</p>*/}
          <p className="single-pack__set">
            <Skeleton />
          </p>
          <p className="single-pack__rsr">
            <Skeleton width={"50%"} />
          </p>
          <p className="single-pack__type">
            <Skeleton width={"70%"} />
          </p>
          <p className="single-pack__rsr">
            <Skeleton width={"70%"} />
          </p>

          <div className="single-pack__hl"></div>
          <p className="single-pack__ask">
            <Skeleton width={"25%"} />
          </p>
          <span className="single-pack__price no-margin">
            <Skeleton />
          </span>
        </div>
      </article>
    </div>
  );
  if (type === "feed") return Array(size).fill(<FeedSkeleton />);
}

export default PinSkeleton;
