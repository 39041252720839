import Bounce from "react-reveal/Bounce";

function SocialMedia() {
  return (
    <section className="social-media-section">
      <div className="container">
        <div className="social-media-wrapper">
          <Bounce left duration={1000}>
            <div>
              <h3 className="join-title">JOIN THE CONVERSATION</h3>
            </div>
          </Bounce>
          <Bounce right duration={1000}>
            <div className="links-row">
              <a className="single-media-link" target="_blank" href="https://discord.gg/NQepVwHEqA">
                <img
                  className="single-media-icon"
                  src="assets/images/ph-sm-icon-discord.svg"
                />
              </a>
              <a className="single-media-link" target="_blank" href="https://twitter.com/OlympicGamesJam">
                <img
                  className="single-media-icon"
                  src="assets/images/ph-sm-icon-twitter.svg"
                />
              </a>
              <a className="single-media-link" target="_blank" href="https://www.facebook.com/OlympicGamesJam/">
                <img
                  className="single-media-icon"
                  src="assets/images/ph-sm-icon-fb.svg"
                />
              </a>
              <a className="single-media-link" target="_blank" href="https://www.instagram.com/olympicgamesjam/">
                <img
                  className="single-media-icon"
                  src="assets/images/ph-sm-icon-instagram.svg"
                />
              </a>
            </div>
          </Bounce>
        </div>
      </div>
    </section>
  );
}

export default SocialMedia;
