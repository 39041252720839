import React from "react";
import Fade from "react-reveal/Fade";
const DevSection = () => {
  return (
    <div className="dev-section" id="developer">
      <Fade right cascade duration={1000}>
        <div className="right-background-container"></div>
      </Fade>
      <Fade bottom cascade duration={1000}>
        <div className="container">
          <div className="dev-wrapper">
            <div className="left-col">
              <h1 className="dev__title">THE DEVELOPER</h1>
              <p className="dev__extract">
                Olympic Games Jam: Beijing 2022 is being developed by nWay, the specialist
                behind real-time competitive multiplayer games. nWay has brought
                you Power Rangers: Battle for the Grid, the fan favorite
                crossplay fighting game on console &amp; PC, and Power Rangers:
                Legacy Wars, the #1 iOS game.
                {/* The team at nWay is made up of
                gaming industry veterans from Sony, LucasArts, EA, Ubisoft,
                Capcom, Bandai Namco, Xbox, Warner Bros., Konami, Kabam, Pocket
                gVTG, Zynga, Glu, Jam City and Machine Zone. The team has
                collectively contributed to over a dozen hits, including Marvel
                Contest of Champions, the Injustice series, Star Wars: the Force
                Unleashed, Game of War, and Harry Potter: Hogwarts Mystery.*/}
              </p>
              <div className="row-btn">
                <a className="main-btn" href="https://nway.com/team/" target="_blank">MEET THE TEAM</a>
              </div>
            </div>
            <div className="right-col"></div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default DevSection;
