import { useState } from "react";
import { mailingSubscribe } from "../../services";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";

function Footer({ onOpenModal, onCloseModal }) {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  function handleChange(e) {
    const { value } = e.target;
    setEmailSent(value);
  }

  async function handleEmail() {
    if (emailSent) {
      if (!validateEmail(emailSent)) {
        setError("Input a valid email address.");
        return;
      } else {
        const { data } = await mailingSubscribe(emailSent);
        if (data.success) {
          onOpenModal(
            "Thank you!",
            true,
            "Your subcriptios has been confirmed.",
            "close"
          );
        } else {
          if (!data.body) {
            const { errors } = data;
            for (let i in errors) {
              let error = errors[i];
              let messageError = "";
              Object.entries(error).forEach(([key, value]) => {
                messageError = value.error;
              });
              onOpenModal("Oups!", false, messageError, "close");
            }
          } else onOpenModal("Oups!", false, data.body, "close");
        }
      }
    }
  }
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="footer-section">
      <div className="container">
        <div className="footer-wrapper">
          <Bounce left duration={1000}>
            <div className="left-column">
              <div className="footer-main-logo">
                <a
                  className="single-link"
                  href="https://olympics.com/"
                  target="_blank"
                >
                  <img
                    className="app-logo-link"
                    src="/assets/images/beijing2020-logo.svg"
                  />
                </a>
              </div>
              <p className="legal-lines">
                &copy; 2022 – International Olympic Committee (IOC) – All rights reserved. This product may not be copied, republished, stored in a retrieval system or otherwise reproduced or transmitted, in whole or in part, in any form or by any means whatsoever, without the prior written consent of the IOC and nWay. TM IOC/Beijing 2022/USOPC 36USC220506.
              </p>
            </div>
          </Bounce>
          <Bounce right duration={1000}>
            <div className="right-column">
              <h5 className="column-sponsors-title">Developed by</h5>
              <div className="row-sponsors">
                <a
                  className="single-link"
                  href="https://nway.com/"
                  target="_blank"
                >
                  <img
                    className="app-logo-link"
                    src="/assets/images/ph-nway-logo-2.png"
                  />
                </a>
                <a
                  className="single-link"
                  href="https://www.animocabrands.com/"
                  target="_blank"
                >
                  <img
                    className="app-logo-link"
                    src="/assets/images/ph-animoca-logo.png"
                  />
                </a>
              </div>
              <div className="row-links">
                <a className="footer-link" target="_blank" href="https://support.nwayplay.com/hc/en-us/sections/4578082086807-OGJ-Olympic-Games-Jam-">
                  Support
                </a>
                <span className="separator">|</span>
                <a className="footer-link" target="_blank" href="https://support.nwayplay.com/hc/en-us/articles/15678279339799-Olympic-Games-Jam-Privacy-Policy">
                  Privacy Policy
                </a>
                <span className="separator">|</span>
                <a className="footer-link" target="_blank" href="https://support.nwayplay.com/hc/en-us/articles/15626616448407">
                  Terms of service
                </a>
              </div>
            </div>
          </Bounce>
        </div>
      </div>
    </div>
  );
}

export default Footer;
