import React from "react";
import {useState} from "react"; 
function SocialMediaFloatingButtons() {
  const [show, setShow] = useState (false)
  function handleClick(){
    setShow(prevState => !prevState)
  }
  return (
    <>
      <div className="social-media-floating-buttons">
        { show && (
          <div className="social-media-menu">
            <a href="https://discord.gg/NQepVwHEqA" target="_blank" className="social-floating-link discord-link">
              <img src="assets/images/icon-discord-floating.svg" />
            </a>
            <a href="https://twitter.com/OlympicGamesJam" target="_blank" className="social-floating-link twitter-link">
              <img src="assets/images/icon-twiter-floating.svg" />
            </a>
            <a href="https://www.facebook.com/OlympicGamesJam/" target="_blank" className="social-floating-link fb-link">
              <img src="assets/images/icon-fb-floating.svg" />
            </a>
            <a href="https://www.instagram.com/olympicgamesjam/" target="_blank" className="social-floating-link insta-link">
              <img src="assets/images/icon-instagram-floating.svg" />
            </a>
          </div>
        )}
        <button onClick={handleClick} className="social-button">
          <img src="assets/images/share.png" />
        </button>
      </div>
    </>
  );
}

export default SocialMediaFloatingButtons;
