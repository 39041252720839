import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/scss/styles.scss";
import Home from "./containers/Home";
import Media from "./containers/Media";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsOfService from "./containers/TermsOfService";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/media" element={<Media />} />
      <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route exact path="/termsofservice" element={<TermsOfService />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
