import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function Piece(item) {
  function getImageFrame() {
    let image = "";
    // debugger;
    switch (item.item?.rarity) {
      case "Common":
        image = item.item?.setName.includes("Tokyo")
          ? "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Tokyo2020/tokyo2020_common_frame.png"
          : "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Premiere/common_premiere.png";
        break;
      case "Rare":
        image = item.item?.setName.includes("Tokyo")
          ? "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Tokyo2020/tokyo2020_rare_frame.png"
          : "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Premiere/rare_premiere.png";
        break;
      case "Epic":
        image = item.item?.setName.includes("Tokyo")
          ? "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Tokyo2020/tokyo2020_epic_frame.png"
          : "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Premiere/epic_premiere.png";
        break;
      case "Legendary":
        image = item.item?.setName.includes("Tokyo")
          ? "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Tokyo2020/tokyo2020_legendary_frame.png"
          : "https://olympicpins-storage.s3.us-east-2.amazonaws.com/images/pins/Premiere/legendary_premiere.png";
        break;
    }
    return image;
  }
  return (
    <>
      <Fade bottom duration={1000}>
        <a
          href={`https://nwayplay.com/pinForSale/${item.item.setPinId}`}
          target="_blank"
        >
          <article className="single-nft">
            <div className="single-nft__image-container">
              <img className="back-frame" src={getImageFrame()} />
              <img
                className="image-in-frame"
                src={item.item.imageURL}
                alt=""
              ></img>
            </div>
            <div className="single-nft__info">
              <h2 className="single-nft__name">{item.item.pinName}</h2>
              {/* <p className="single-nft__serial">#{item.item.serial_max}</p> */}
              {/* <p className="single-nft__price">
              {item.item.lowestAsk}
              <span className="single-nft__currency"> USDT</span>
            </p>
            <p className="single-pack__description">{item?.description}</p> */}
              {/*
            <p>{item.item.setName}</p>
            <p>
              {item.item.rarity} | {item.serial_max}
            </p>
            <p>{item.item.type}</p>
            <p>
              {" "}
              Listing count:
              {item.item.listingCount}
            </p>

            <div></div>
            <p>lowest ask</p>
            <span>${item.item.lowestAsk}</span>
            <span>usd</span>
            */}
            </div>
          </article>
        </a>
      </Fade>
    </>
  );
}

export default Piece;
