import React, { useEffect, useState } from "react";
import "./style.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SocialMediaFloatingButtons from "../../components/SocialMediaFloatingButtons";
// import Newsletter from "../../components/NewsLetter";
// import Image from "../../components/Image";
// import Gallery from "../../components/PagesContainers/Gallery";
// import News from "../../components/PagesContainers/News";
// import Olympic from "../../components/PagesContainers/Olympic";
import BannerSlider from "../../components/BannerSlider/bannerSlider";
import HomeSlider from "../../components/HomeSlider/homeSlider";
import AboutSection from "../../components/AboutSection/aboutsection";
import RoadMap from "../../components/RoadMap/roadmap";
import DevSection from "../../components/DevSection/devsection";
import Fullvideo from "../../components/FullVideo";
import SocialMedia from "../../components/SocialMedia";
// import NewsSlider from "../../components/PagesContainers/NewsSlider";
import MainBar from "../../components/MainBar";
import ModalMessage from "../../components/modalMessage";
import NewsletterForm from "../../components/NewsletterForm";
import ModalVideo from "react-modal-video";
function Home() {
  const [messageIsOpenModal, setMessageIsOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [isCheckOkModal, setIsCheckOkModal] = useState(true);
  const [messageModal, setMessageModal] = useState(null);
  const [messageButtonModal, setMessageButtonModal] = useState(null);
  const [videoModal, setVideoModal] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('/#trailer')) {
      handleOpenVideoModal()
    }
  }, []);

  const handleCloseModalMessage = () => {
    setMessageIsOpenModal(false);
    setTitleModal(null);
    setIsCheckOkModal(true);
    setMessageModal(null);
    setMessageButtonModal(null);
  };

  const handleOpenModalMessage = (title, isCheckOk, message, messageButton) => {
    // debugger;
    setTitleModal(title);
    setIsCheckOkModal(isCheckOk);
    setMessageModal(message);
    setMessageButtonModal(messageButton);
    setMessageIsOpenModal(true);
  };

  const handleClickVideoIsOpen = () => {
    handleOpenVideoModal(true)
  };

  const handleOpenVideoModal = () => {
    setVideoModal(true)
  };

  const handleCloseVideoModal = () => {
    setVideoModal(false)
  };

  return (
    <>
      <MainBar />
      <Navbar onClickVideoSetOpen={handleClickVideoIsOpen} />
      <Header onClickVideoSetOpen={handleClickVideoIsOpen} />
      <SocialMediaFloatingButtons />
      <Fullvideo />
      <BannerSlider />
      {/*<NftSliderV2 />*/}
      <HomeSlider />
      <AboutSection />
      <RoadMap />
      <DevSection />
      {/*<News />*/}
      {/* <NewsSlider /> */}
      {/*
      <Olympic />
      <Gallery />
      */}
      <SocialMedia />
      <NewsletterForm onOpenModal={handleOpenModalMessage}
        onCloseModal={handleCloseModalMessage} />
      <Footer

      />

      <ModalVideo
        channel="youtube"
        autoPlay
        playsInline
        isOpen={videoModal}
        videoId="IUVaqRnHmUI"
        onClose={handleCloseVideoModal}
      />

      <ModalMessage
        isOpen={messageIsOpenModal}
        onRequestClose={handleCloseModalMessage}
        isCheckOk={isCheckOkModal}
        title={titleModal}
        message={messageModal}
        messageButton={messageButtonModal}
      />
    </>
  );
}

export default Home;
