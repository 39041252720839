import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import "./modalMessage.scss";

const customStyles = {
  content: {
    display: "flex",
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
};

const ModalMessage = ({
  isOpen,
  onRequestClose,
  isCheckOk = true,
  title = null,
  message = null,
  messageButton = "CLOSE",
}) => (
  <Modal
    id="modal-message"
    isOpen={isOpen}
    //onAfterOpen={afterOpenModal}
    onRequestClose={onRequestClose}
    style={customStyles}
    className="modal_container"
    overlayClassName="modal_overlay"
    shouldCloseOnOverlayClick={true}
    style={{
      overlay: {
        backgroundColor: "rgba(0,0,0,.50)",
      },
    }}
  >
    <div className="modal-content">
      <div class="check">
        {isCheckOk ? (
          <img class="icon-success" src="/assets/images/check-ok.png" />
        ) : (
          <img class="icon-success" src="/assets/images/check-error.png" />
        )}
      </div>
      <h1 class="modal-header">{title}</h1>
      <p class="modal-pargraph">{message}</p>
      <div className="modal-button-container">
        <button class="btn-normal" onClick={onRequestClose}>
          {messageButton}
        </button>
      </div>
    </div>
  </Modal>
);

export default ModalMessage;
