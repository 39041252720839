import "./style.scss";
import Fade from "react-reveal/Fade";

function Media() {
  return (
    <>
      <section>
        <div className="banner-media">
          <video
            playsInline="playsInline"
            className="video-container fade-in-up"
            autoPlay="none"
            muted="muted"
            loop="loop"
            controls="controls"
          >
            <source
              src="https://olympicpins-storage.s3.us-east-2.amazonaws.com/videos/Olympic+Games+Jam+-+Beijing+2022+-+Launch+Trailer-720.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="container">
          <div className="row-logos">
            <div className="logo-wrapper">
              <img
                className="ogj-logo"
                src="/assets/images/olympic-jam-game-logo.png"
              />
            </div>
            <div className="logo-wrapper">
              <svg width="84" height="130" viewBox="0 0 84 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="84" height="130" fill="white"/>
                <g clip-path="url(#clip0_862_255)">
                <path d="M81.3701 2.63V127.37H2.63118V2.63H81.3701ZM82.0013 2H2V128H82.0013V2Z" fill="black"/>
                <path d="M20.0355 33.4722L22.6296 36.5076C22.6296 36.5076 43.316 18.8839 62.22 21.2175L62.2099 21.0663C62.1076 21.0234 59.2269 20.1553 54.3984 20.1553C46.7408 20.1553 34.1828 22.3389 20.038 33.4722" fill="black"/>
                <path d="M63.4243 22.9967H63.4129C62.225 26.2223 57.6843 25.6957 51.5202 25.169C48.1408 24.8792 44.2729 24.5906 40.203 24.917C29.5032 25.7839 20.0355 33.4724 20.0355 33.4724L22.6296 36.5077C22.6296 36.5077 27.544 32.3094 34.9503 29.9885C38.8637 28.7612 44.1656 29.458 48.9222 30.1561C53.3203 30.7999 57.2538 31.4438 59.1979 30.5693C61.1015 29.5298 63.1718 26.1593 63.5089 22.9917C63.4811 22.9917 63.4521 22.9917 63.4218 22.9917" fill="black"/>
                <path d="M23.7077 36.4761C32.2312 33.1194 41.3808 36.6235 41.3808 36.6235L48.7026 34.0342C45.4533 32.8243 42.0148 32.1989 38.5468 32.187C30.301 32.187 24.6608 35.8322 23.7026 36.471" fill="black"/>
                <path d="M30.9398 39.9021C39.3471 33.9498 48.8654 34.0342 48.8654 34.0342C39.5466 38.0121 36.0246 44.4141 36.0246 44.4141L30.9473 39.8996" fill="black"/>
                <path d="M48.2102 18.1342C43.9384 17.8104 39.515 18.1128 36.5081 18.3975C28.3948 23.6719 23.6963 30.4154 23.6963 30.4154C36.32 20.1363 48.2102 18.1342 48.2102 18.1342Z" fill="black"/>
                <path d="M45.3724 14.0822C42.9396 13.5034 40.4406 13.2492 37.9408 13.3262C36.0824 15.0281 34.3588 16.8712 32.7853 18.8387C32.7853 18.8387 34.2484 18.6119 36.5093 18.3977C39.5163 18.1129 43.9396 17.8105 48.2115 18.1343L45.3724 14.0822Z" fill="black"/>
                <path d="M29.9476 14.7789L32.7854 18.8387C34.3594 16.8728 36.0835 15.0315 37.9421 13.3312C39.5517 11.8696 41.4023 10.4256 43.2921 9.43652C43.2921 9.43652 34.6903 10.9183 29.9488 14.7789" fill="black"/>
                <path d="M44.5367 53.0049C33.8697 47.9435 30.941 39.9009 30.941 39.9009C36.58 44.4041 48.6458 48.5798 48.6458 48.5798C48.6458 48.5798 45.5088 53.4673 44.5367 53.0061" fill="black"/>
                <path opacity="0.5" d="M41.887 24.8162C41.3299 24.8397 40.7686 24.8741 40.203 24.9195C30.7025 25.6868 22.1739 31.8356 20.3839 33.2027L20.0355 33.4724L22.6296 36.5077C22.8392 36.33 31.0976 29.3522 41.8795 24.8199" fill="black"/>
                <path d="M55.7604 70.2732C54.1379 70.2798 52.5777 70.8976 51.3919 72.0031C50.2062 73.1086 49.4824 74.6202 49.3653 76.2355C49.7183 76.2652 50.068 76.3255 50.4105 76.4157C50.4979 75.0367 51.1122 73.7437 52.1267 72.8035C53.1411 71.8632 54.4784 71.3474 55.8627 71.3625C57.247 71.3775 58.5728 71.9222 59.5665 72.8842C60.5602 73.8463 61.1462 75.1523 61.2035 76.5329C61.2608 77.9135 60.7851 79.2634 59.8745 80.3042C58.9639 81.345 57.6879 81.9973 56.3096 82.1266C54.9313 82.2559 53.5558 81.8523 52.4669 80.999C51.378 80.1458 50.6586 78.908 50.4572 77.5409C50.103 77.426 49.738 77.347 49.3678 77.3053C49.4782 78.6306 49.9967 79.8894 50.8521 80.9091C51.7075 81.9287 52.8581 82.6592 54.1459 83.0005C54.4897 83.0907 54.8407 83.1514 55.1949 83.1819C55.3792 83.1983 55.5736 83.2071 55.7604 83.2071C59.3064 83.2071 62.1808 80.3091 62.1808 76.7408C62.1808 73.1725 59.3014 70.2732 55.7604 70.2732Z" fill="black"/>
                <path d="M54.1534 83.0029C54.0799 84.0429 53.7038 85.0387 53.0711 85.8682C52.4385 86.6977 51.577 87.3248 50.5923 87.6724C49.6076 88.02 48.5427 88.073 47.5282 87.825C46.5138 87.5769 45.594 87.0386 44.8818 86.2761C44.1696 85.5135 43.696 84.56 43.5191 83.5326C43.3422 82.5052 43.4698 81.4485 43.8862 80.4925C44.3026 79.5364 44.9896 78.7225 45.8629 78.1507C46.7362 77.5789 47.7578 77.2741 48.8023 77.2737C48.9933 77.275 49.1842 77.2859 49.3741 77.3065C50.5595 77.4331 51.6686 77.9512 52.5253 78.7787C53.382 79.6062 53.9373 80.6956 54.1029 81.874C54.459 81.9906 54.8262 82.07 55.1987 82.1109C55.0818 80.6012 54.4318 79.1819 53.3644 78.1059C52.297 77.0299 50.8816 76.3671 49.3703 76.2355C49.1822 76.2191 48.9916 76.2078 48.8023 76.2078C45.2525 76.2141 42.3793 79.1121 42.3793 82.6766C42.3793 86.2411 45.2512 89.1429 48.801 89.1429C50.4238 89.1374 51.9846 88.5203 53.1711 87.4152C54.3576 86.3102 55.0822 84.7986 55.1999 83.1831C54.8465 83.1507 54.4961 83.0905 54.1522 83.0029" fill="black"/>
                <path d="M48.2594 76.2317C48.1397 74.6167 47.414 73.1061 46.2274 72.0016C45.0408 70.8971 43.4805 70.2799 41.858 70.2732C38.3133 70.2732 35.4388 73.1712 35.4388 76.7408C35.4388 80.3104 38.3133 83.2071 41.858 83.2071C42.0423 83.2071 42.2228 83.1983 42.4033 83.1819C42.3869 83.0181 42.3806 82.8442 42.3806 82.6779C42.3806 82.4898 42.3903 82.3018 42.4096 82.1147C42.2253 82.1323 42.0436 82.1411 41.858 82.1411C40.7996 82.151 39.7616 81.8509 38.8724 81.2779C37.9832 80.705 37.2818 79.8843 36.8551 78.9176C36.4284 77.9509 36.295 76.8804 36.4716 75.8388C36.6481 74.7972 37.1267 73.8301 37.8483 73.0573C38.5698 72.2844 39.5026 71.7397 40.5311 71.4905C41.5597 71.2414 42.6388 71.2987 43.6351 71.6555C44.6313 72.0122 45.5008 72.6527 46.1361 73.4977C46.7713 74.3426 47.1444 75.355 47.2092 76.4094C47.2155 76.519 47.2218 76.6274 47.2218 76.742C47.2207 77.006 47.2009 77.2696 47.1625 77.5308C47.0194 78.5249 46.6008 79.4592 45.9539 80.2285C45.307 80.9977 44.4576 81.5711 43.5016 81.8841C43.4622 82.1469 43.4428 82.4122 43.4435 82.6779C43.4435 82.7875 43.4435 82.8959 43.4536 83.0105C44.7464 82.6712 45.9022 81.9408 46.7622 80.9194C47.6223 79.8981 48.1444 78.636 48.2569 77.3065C48.2721 77.1175 48.2834 76.9285 48.2834 76.742C48.2834 76.5694 48.2746 76.4044 48.262 76.238" fill="black"/>
                <path d="M35.4464 76.2354C35.802 76.2678 36.154 76.3324 36.498 76.4281L35.4464 76.2354Z" fill="black"/>
                <path d="M40.1992 82.9916C40.1281 84.0326 39.7538 85.0302 39.1223 85.8617C38.4907 86.6932 37.6295 87.3224 36.6446 87.6719C35.6596 88.0214 34.5937 88.076 33.5781 87.829C32.5624 87.582 31.6412 87.0442 30.9277 86.2816C30.2142 85.519 29.7395 84.5649 29.5619 83.5367C29.3844 82.5084 29.5118 81.4508 29.9284 80.4938C30.345 79.5368 31.0327 78.7222 31.907 78.1501C32.7812 77.5781 33.8039 77.2734 34.8493 77.2737C35.0514 77.2747 35.2534 77.2878 35.454 77.3128C35.8252 77.3541 36.1908 77.4352 36.5446 77.5547C37.4836 77.8734 38.3166 78.4437 38.9525 79.2033C39.5884 79.9629 40.0027 80.8825 40.15 81.8614C40.5024 81.9835 40.8674 82.0659 41.2381 82.1071C41.1271 80.7891 40.6128 79.537 39.7649 78.5205C38.917 77.504 37.7765 76.7723 36.4979 76.4245C36.1536 76.3331 35.8023 76.2699 35.4477 76.2355C35.2507 76.2178 35.05 76.2078 34.8493 76.2078C31.2995 76.2078 28.4251 79.1058 28.4251 82.6766C28.4215 83.5222 28.5847 84.3603 28.9056 85.143C29.2265 85.9256 29.6987 86.6375 30.2953 87.238C30.8918 87.8384 31.601 88.3157 32.3824 88.6426C33.1638 88.9694 34.0021 89.1395 34.8493 89.1429C38.2236 89.1429 40.9882 86.5133 41.2444 83.1819C40.8911 83.149 40.5414 83.0853 40.1992 82.9916Z" fill="black"/>
                <path d="M34.3002 76.2317C34.0401 72.9003 31.2705 70.2732 27.8974 70.2732C24.354 70.2732 21.4771 73.1712 21.4771 76.7408C21.4771 80.3104 24.354 83.2071 27.8974 83.2071C28.0792 83.2071 28.261 83.1983 28.4428 83.1819C28.4302 83.0181 28.4213 82.8442 28.4213 82.6779C28.4213 82.4864 28.4302 82.2999 28.4453 82.1147C28.2673 82.1323 28.083 82.1411 27.8974 82.1411C26.8392 82.1507 25.8013 81.8504 24.9122 81.2773C24.0232 80.7042 23.322 79.8835 22.8955 78.9167C22.4689 77.9499 22.3357 76.8796 22.5124 75.838C22.6891 74.7965 23.1679 73.8295 23.8895 73.0568C24.6111 72.284 25.5438 71.7394 26.5723 71.4904C27.6009 71.2413 28.6799 71.2988 29.6761 71.6555C30.6722 72.0123 31.5417 72.6528 32.1769 73.4978C32.8121 74.3427 33.1852 75.355 33.2499 76.4094C33.2575 76.519 33.2625 76.6274 33.2625 76.742C33.2623 77.0061 33.242 77.2698 33.2019 77.5308C33.0593 78.525 32.6408 79.4596 31.9939 80.2289C31.347 80.9982 30.4973 81.5715 29.5411 81.8841C29.5033 82.147 29.4844 82.4123 29.4842 82.6779C29.4842 82.7875 29.4842 82.8959 29.4931 83.0105C30.7848 82.6705 31.9395 81.9396 32.7984 80.9183C33.6573 79.897 34.1784 78.6353 34.2901 77.3065C34.3065 77.1175 34.3153 76.9285 34.3153 76.742C34.3153 76.5694 34.3065 76.4044 34.2964 76.238" fill="black"/>
                <path d="M21.8343 62.3665L21.4405 61.2199C20.6167 61.1309 19.7855 61.1343 18.9624 61.23C19.0887 60.852 19.2452 60.4362 19.4384 59.912C20.4163 59.7629 21.4063 59.708 22.3948 59.7482L22.0313 58.5487C20.9188 58.4707 19.8022 58.4707 18.6898 58.5487V58.5588C18.6404 58.5573 18.5912 58.565 18.5446 58.5815C17.7603 60.387 17.106 62.246 16.5867 64.1444L17.4249 65.3741H17.9639C19.5823 65.3615 19.7312 65.32 21.6387 65.1751L21.2284 63.8949C20.2365 64.075 19.2263 64.1339 18.2202 64.07C18.3338 63.5106 18.431 63.0721 18.5295 62.684C19.6204 62.4871 20.7258 62.3809 21.8343 62.3665Z" fill="black"/>
                <path d="M15.1728 61.0108C15.6379 60.7042 15.9784 60.2423 16.1335 59.708L15.7548 58.5324C15.7548 58.54 15.7472 58.5475 15.7447 58.5563L15.7358 58.5324C12.8059 57.5433 10.1536 58.2111 9.37097 58.4543L10.266 59.7672C10.6456 59.6345 11.0347 59.5305 11.4299 59.456C10.6829 61.3246 10.0831 63.2486 9.63607 65.2104C15.5364 66.0924 16.3001 62.7484 16.3001 62.7484L15.905 61.5766C15.905 61.5766 15.732 61.2779 15.1728 61.0108ZM11.6382 63.8004C11.8982 62.7143 12.3287 61.0927 12.9473 59.2771C13.6709 59.2559 14.3947 59.3134 15.1059 59.4484C14.1705 60.3115 12.7869 60.5749 12.7869 60.5749L13.1821 61.7441C13.1821 61.7441 14.5618 61.7441 15.4695 62.3603C14.5202 63.6064 12.8488 63.8181 11.6344 63.8004H11.6382Z" fill="black"/>
                <path d="M25.7237 58.5464H24.3439C23.3677 60.742 22.6014 63.0248 22.0552 65.3643H23.743C24.2328 63.0457 24.8948 60.7668 25.7237 58.5464Z" fill="black"/>
                <path d="M30.2707 58.5464C29.297 60.7424 28.5329 63.0251 27.9883 65.3643H29.6761C30.1634 63.0459 30.8229 60.767 31.6492 58.5464H30.2707Z" fill="black"/>
                <path d="M37.0483 58.5464C36.4406 59.921 35.9129 61.3295 35.4679 62.7649C35.0614 61.5049 34.66 59.9689 34.5577 58.5464H33.1792C32.2034 60.7421 31.4375 63.0248 30.8918 65.3643H32.5783C32.5783 65.3643 32.8989 63.7061 33.5958 61.3965C33.8482 62.6565 34.2143 64.1206 34.7597 65.3643H36.4462C36.935 63.046 37.5954 60.7672 38.4218 58.5464H37.0483Z" fill="black"/>
                <path d="M72.8794 63.4729C71.9023 63.0949 70.7334 63.0697 69.6149 63.202C70.433 62.265 71.1443 61.2402 71.7357 60.1465L71.2535 58.7114C70.0782 58.4342 68.0824 58.4984 66.5133 59.3741L66.993 60.8017C66.993 60.8017 68.2453 60.0873 70.1211 60.0293C69.644 60.8383 68.6063 62.1109 66.3492 63.7489L65.824 63.2512C65.0291 62.9777 64.1875 62.8654 63.3485 62.921C64.1819 61.9348 64.8998 60.8568 65.4882 59.708L65.0111 58.2767C63.8295 58.0247 61.8981 58.0637 60.3492 58.9722L60.8629 60.4225C60.8629 60.4225 62.3349 59.4623 64.0252 59.4485C63.553 60.3305 62.4005 61.8425 59.593 63.8522L59.5475 63.866L60.7291 65.5482C60.7291 65.5482 62.9231 64.2062 65.6423 64.244L65.6157 64.2554L66.7595 65.9627C66.7595 65.9627 70.8129 63.5473 74.802 64.9043L72.8794 63.4729Z" fill="black"/>
                <path d="M59.0426 58.9823C59.0426 58.9899 57.5644 58.5124 57.5644 58.5124C56.4485 58.8626 55.4555 59.5225 54.7013 60.4149C53.55 61.801 53.5412 62.9992 53.824 63.8636L53.5172 63.5662C52.7212 63.2791 51.8754 63.1553 51.0303 63.2021C51.8891 62.2087 52.6319 61.121 53.2445 59.9601L52.7636 58.5262C51.5201 58.2591 49.5622 58.3826 48.0007 59.2709L48.4867 60.7035C48.9638 60.3835 50.4509 59.8921 51.7284 59.7913C51.2235 60.6733 50.0406 62.1512 47.2697 64.0753L47.2319 64.0929L48.2733 65.6175L48.3895 65.7901C48.3895 65.7901 51.0783 64.1849 54.1762 64.6183C54.3211 64.8529 54.4941 65.0691 54.6912 65.2622L56.2894 65.7561C56.2894 65.7561 58.087 65.4524 59.1537 63.8535C61.2353 60.7237 59.0426 58.9823 59.0426 58.9823ZM57.654 63.3621C57.1112 64.006 56.3247 64.4961 55.6961 64.8224C55.2214 63.8636 54.8124 62.3087 56.0596 60.8635C56.6188 60.2134 57.4268 59.7295 58.0592 59.4196C58.4834 60.3809 58.8431 61.9559 57.654 63.3621Z" fill="black"/>
                <path d="M26.9494 58.5451C26.9494 58.5451 26.091 61.6951 24.9561 65.2017C24.5698 66.3974 24.0005 67.1232 23.3365 67.379C22.9288 67.5352 22.622 67.4659 22.3115 67.4294L24.0258 68.0921C25.7931 67.8162 26.3056 66.4541 26.6767 65.4688C27.0933 64.3739 28.6385 58.5388 28.6385 58.5388L26.9494 58.5451Z" fill="black"/>
                <path d="M44.3045 59.8757L43.8349 58.4481C39.5226 58.4116 37.5748 61.1773 37.701 63.5725C37.7107 63.7815 37.7425 63.9889 37.7957 64.1912L38.4521 65.1551C39.159 65.5999 40.3293 65.4071 41.7999 64.5251C41.4212 65.1954 40.8872 65.7939 40.15 65.959C40.1942 65.959 41.3341 66.5449 41.5942 66.4239C42.3592 66.0661 43.7932 64.985 44.0659 62.8228L42.5233 62.2369C42.5242 62.2407 42.5242 62.2446 42.5233 62.2483C41.5563 63.7603 39.5516 64.3399 38.9141 63.9984C37.9181 63.468 39.9127 59.698 44.3045 59.8757Z" fill="black"/>
                <path d="M74.8689 58.8813H74.5003V58.749H75.3966V58.8813H75.0267V59.9599H74.8689V58.8813Z" fill="black"/>
                <path d="M76.5516 59.4269C76.5428 59.2581 76.5314 59.0489 76.5314 58.9053C76.4873 59.0451 76.4393 59.1976 76.3787 59.3639L76.1641 59.9498H76.0454L75.8485 59.374C75.7904 59.2026 75.7437 59.0476 75.7096 58.9053C75.7096 59.0552 75.697 59.2555 75.6869 59.4395L75.6541 59.9574H75.5051L75.5897 58.749H75.7892L75.9962 59.3337C76.0454 59.4823 76.0858 59.6146 76.1224 59.7406C76.1553 59.6146 76.1982 59.4886 76.2487 59.3337L76.4633 58.749H76.664L76.7385 59.9574H76.5845L76.5516 59.4269Z" fill="black"/>
                <path d="M9.97943 96.0627H74.0206" stroke="black" stroke-miterlimit="10"/>
                <path d="M22.9692 102.661C25.1531 102.661 26.5871 104.094 26.5871 106.536C26.5871 109.126 25.143 110.506 22.9692 110.506C20.7954 110.506 19.3513 109.12 19.3513 106.536C19.3513 104.094 20.7929 102.661 22.9692 102.661ZM22.9692 109.179C24.2985 109.179 25.0584 108.242 25.0584 106.533C25.0584 104.976 24.2884 103.986 22.9806 103.986C21.6513 103.986 20.8812 104.986 20.8812 106.533C20.8812 108.232 21.6614 109.179 22.9692 109.179Z" fill="black"/>
                <path d="M27.8835 102.787H32.7462V104.073H29.3378V106.009H32.3448V107.263H29.3391V110.369H27.8835V102.787Z" fill="black"/>
                <path d="M33.8849 102.787H38.7475V104.073H35.3391V106.009H38.3448V107.263H35.3404V110.369H33.8849V102.787Z" fill="black"/>
                <path d="M39.9064 102.787H41.3619V110.369H39.9064V102.787Z" fill="black"/>
                <path d="M49.0207 109.274C48.1231 110.137 47.1953 110.506 46.0352 110.506C43.7629 110.506 42.6798 108.832 42.6798 106.579C42.6798 104.357 43.8412 102.661 46.0983 102.661C47.68 102.661 48.9045 103.536 48.9878 105.325H47.5222C47.4692 104.652 47.1322 103.978 46.1084 103.978C44.9382 103.978 44.2414 104.926 44.2414 106.569C44.2414 108.316 44.9899 109.189 46.1829 109.189C47.0892 109.189 47.6977 108.779 48.2393 108.316L49.0207 109.274Z" fill="black"/>
                <path d="M50.1151 102.787H51.5706V110.369H50.1151V102.787Z" fill="black"/>
                <path d="M56.7287 102.787L59.4617 110.369H57.9065L57.3472 108.653H54.6609L54.103 110.369H52.5313L55.2631 102.787H56.7287ZM56.9408 107.432L55.9902 104.452L55.0523 107.432H56.9408Z" fill="black"/>
                <path d="M61.8652 102.787V109.087H65.1663V110.371H60.4084V102.787H61.8652Z" fill="black"/>
                <path d="M9.95923 113.543H11.4892L13.2187 119.432L14.986 113.547H16.4844L14.0531 121.123H12.3539L9.95923 113.543Z" fill="black"/>
                <path d="M17.416 113.543H18.8715V121.123H17.416V113.543Z" fill="black"/>
                <path d="M22.9389 113.543C25.4283 113.543 26.7146 114.921 26.7146 117.248C26.7146 119.975 25.3967 121.123 22.7167 121.123H20.4318V113.543H22.9389ZM21.8949 114.827V119.849H22.9603C24.5105 119.849 25.196 119.007 25.196 117.354C25.196 115.733 24.5105 114.827 22.9603 114.827H21.8949Z" fill="black"/>
                <path d="M28.0578 113.543H33.1073V114.827H29.5133V116.649H32.7854V117.933H29.5158V119.849H33.1767V121.123H28.0578V113.543Z" fill="black"/>
                <path d="M37.624 113.417C39.8079 113.417 41.2419 114.848 41.2419 117.291C41.2419 119.881 39.7978 121.26 37.624 121.26C35.4502 121.26 34.006 119.874 34.006 117.291C34.006 114.848 35.4515 113.417 37.624 113.417ZM37.624 119.933C38.9533 119.933 39.7132 118.996 39.7132 117.287C39.7132 115.729 38.9432 114.738 37.6354 114.738C36.3061 114.738 35.5348 115.739 35.5348 117.287C35.5348 118.986 36.32 119.936 37.624 119.936V119.933Z" fill="black"/>
                <path d="M48.2342 117.08H50.9761V120.091C50.1013 121.05 48.9096 121.26 48.0436 121.26C45.3535 121.26 44.4572 119.46 44.4572 117.333C44.4572 115.037 45.5858 113.417 47.9603 113.417C50.0697 113.417 50.8397 114.532 50.9344 115.89H49.4259C49.3842 115.249 48.9828 114.732 47.9603 114.732C46.6626 114.732 45.9872 115.774 45.9872 117.311C45.9872 118.976 46.6184 119.965 48.1332 119.965C48.6824 119.965 49.2088 119.849 49.5887 119.533V118.29H48.2342V117.08Z" fill="black"/>
                <path d="M55.9549 113.543L58.6866 121.123H57.1251L56.5658 119.407H53.8871L53.3279 121.123H51.7562L54.488 113.543H55.9549ZM56.1657 118.186L55.2164 115.206L54.2772 118.186H56.1657Z" fill="black"/>
                <path d="M66.2697 121.123L65.7735 116.006L64.1072 121.123H62.9357L61.2694 115.996L60.7733 121.123H59.3847L60.1232 113.543H61.681L63.5164 119.112L65.3936 113.543H66.892L67.6406 121.123H66.2697Z" fill="black"/>
                <path d="M68.9257 113.543H73.9751V114.827H70.3774V116.649H73.6482V117.933H70.3812V119.849H74.042V121.123H68.9257V113.543Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_862_255">
                <rect width="80" height="126" fill="white" transform="translate(2 2)"/>
                </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div className="screens-container">
          <div className="container">
            <Fade bottom duration={1000}>
              <div className="title-container">
                <h1>SCREENSHOTS</h1>
              </div>
              <div className="images-container">
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/4snowboardslalom.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/5skicross1.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/6skeleton1.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/7snowboard_slopestyle.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/8skeleton2_1920x1080.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
                <div className="single-screenshot-wrapper">
                  <img
                    src="assets/images/9skicross_w_ui_1920x1080.jpg"
                    className="fade-in-up single-screenshot"
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
}

export default Media;
